
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import SignupPassword from '@/components/shared/SignupPassword.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { Form as Validation } from 'vee-validate'

export default defineComponent({
  components: {
    PageAuth,
    Validation,
    SignupPassword,
    TmFormLine,
    TmButton,
  },
  setup() {
    const forgotPassword = ref({
      password: '',
      confirmPassword: '',
    })

    return {
      forgotPassword,
    }
  },
})
